<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="search.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                @change="dateCheck"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-select size="small" @input="handleInputStatusFilter" v-model="status_filter" placeholder="Select">
                <el-option
                  v-for="item in statusFilterOptions"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select> &nbsp;
              <el-select v-model="callType" size="small" placeholder="Select">
                <el-option v-for="(item, i) in callTypeOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp;
              <el-button :loading="loading.table" @click="page = 1; getList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
              <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Customer Phone</th>
                    <th>Call Date & Time</th>
                    <th>End Time</th>
                    <th>Call Duration</th>
                    <th>Call Direction</th>
                    <th>Agent Name</th>
                    <th>Agent Email</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="(item, i) in reportList" :key="i">
                    <td>
                      <el-tag size="mini" :type="item.status_color">
                        {{ item.status_str }}
                      </el-tag>
                    </td>
                    <td>
                      {{ item.identifier }}
                    </td>
                    <td>
                      {{ item.created_at ? formatDate(item.created_at) : '' }}
                    </td>
                    <td>
                      {{ item.terminated_at ? formatDate(item.terminated_at) : '' }}
                    </td>
                    <td>
                      {{ item.duration ? humanizeDuration(item.duration * 1000) : '-' }}
                    </td>
                    <td>
                      {{ item.type_str }}
                    </td>
                    <td>
                      {{ item.agent?.name ? item.agent.name : '-' }}
                    </td>
                    <td>
                      {{ item.agent?.name ? item.agent.email : '-' }}
                    </td>
                    <!-- <td>
                      {{ item.agent?.agent_group ? item.agent.agent_group.name : '-' }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="totalRow == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="totalRow > page_limit" v-model="currentPage" :total-rows="totalRow" :per-page="page_limit"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isEmpty, upperFirst, difference, isMatch,
} from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'CallReports',
  metaInfo: {
    title: 'Call Reports',
  },
  data() {
    return {
      upperFirst,
      page_limit: 50,
      loading: {
        table: false,
      },
      callType: this.$route.query.direction_filter || '',
      status_filter: this.$route.query.status_filter || '',
      statusFilterOptions: [
        {
          value: '',
          text: 'All',
        },
        {
          value: 'calling',
          text: 'Progress',
        },
        {
          value: 'answered',
          text: 'Answered',
        },
        {
          value: 'no_answer',
          text: 'Failed',
        },
      ],
      callTypeOptions: [
        {
          value: '',
          text: 'All',
        },
        {
          value: 'incoming',
          text: 'Incoming',
        },
        {
          value: 'outgoing',
          text: 'Outgoing',
        },
      ],
      reportList: [],
      loaderStack: 0,
      loader: null,
      search: {
        date_range: [
          this.$route.query.start_date || new Date(),
          this.$route.query.end_date || new Date(),
        ],
      },
      page: this.$route.query.page || 1,
      totalRow: 0,
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
  },
  async mounted() {
    this.showLoader();
    await this.getList();
    this.hideLoader();
  },
  methods: {
    dateCheck() {
      const [start_date, end_date] = this.search.date_range;
      const minTime = moment(start_date);
      const maxTime = moment(end_date);
      const diff = maxTime.diff(moment(minTime), 'd');
      if (diff > 30) {
        this.$message({
          title: this.$t('general.error.general'),
          type: 'warning',
          message: 'Reporting period that can be selected for 31 days',
          duration: 20 * 100,
          showClose: true,
        });
        this.search.date_range = [start_date, start_date];
      }
    },
    countDuration(start, end) {
      console.log(moment(start).diff(moment(end), 'second'), start, end);
      return start && end ? humanizeDuration(moment(start).diff(moment(end), 'second'), { largest: 2, round: true }) : '-';
    },
    formatDate(string, format = 'DD MMM YYYY, HH:mm') {
      return string ? moment(string).format(format) : '-';
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async downloadReport() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // this.loading.table = true;
            if (isEmpty(this.search.date_range)) {
              this.search.date_range = [new Date(), new Date()];
            }
            const [start_date, end_date] = this.search.date_range;
            const response = await reportApi.downloadCallList({
              workspace_id: this.activeWorkspace._id,
              start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
              end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
              call_type: this.callType,
              status_filter: this.status_filter,
            }).catch(() => {});
            // this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
    async getList() {
      if (isEmpty(this.search.date_range)) {
        this.search.date_range = [new Date(), new Date()];
      }
      this.dateCheck();
      const [start_date, end_date] = this.search.date_range;
      const options = {
        page: this.page,
        start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
        end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
        direction_filter: this.callType,
        status_filter: this.status_filter,
      };
      if (!isMatch(this.$route.query, options)) {
        this.$router.replace({ query: options });
        return;
      }
      this.loading.table = true;
      const response = await reportApi.callList({
        workspace_id: this.activeWorkspace._id,
        ...options,
      }).catch(() => {});
      this.loading.table = false;
      await popupErrorMessages(response);
      this.reportList = response.data.rows.map((item) => {
        item.status_str = 'Failed';
        item.status_color = 'danger';
        if (item.status === 'ended') {
          item.status_str = 'Success';
          item.status_color = 'success';
        } else if (item.status === 'calling') {
          item.status_str = 'Progress';
          item.status_color = 'info';
        }
        if (item.duration) {
          item.status_str = 'Success';
          item.status_color = 'success';
        }
        if (item.status === 'no_answer') {
          if (item.rejected_by) {
            item.status_str = 'Rejected';
            item.status_color = 'danger';
          } else {
            item.status_str = 'No Answer';
            item.status_color = 'info';
          }
        }
        if (item.rejected_by_user) {
          item.agent = item.rejected_by_user;
        }
        if (item.accepted_by_user) {
          item.agent = item.accepted_by_user;
        }
        if (item.direction) {
          item.type_str = upperFirst(item.direction);
        }
        return item;
      });
      this.totalRow = response.data.count;
    },
    handleInputStatusFilter(value) {
      if (value[value.length - 1] === 'all') {
        this.status_filter = ['all'];
      } else if (value.indexOf('all') !== -1) {
        this.status_filter.splice(value.indexOf('all'), 1);
      } else if (difference(['calling', 'ended', 'failed'], this.status_filter).length === 0) {
        this.status_filter = ['all'];
      }
    },
  },
};
</script>
